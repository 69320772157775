@import '../../../theme/variables';

:local {
  .modal {
    color: $main-text-color;
    display: block;
    height: 100%;
    margin-top: 200px;
    min-height: 300px;
  }

  .modalDialog {
    height: 100%;
    width: 768px;
  }

  .modalContent {
    border-radius: 0;
    max-height: 50%;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 30px 45px;
  }

  .modalHeader {
    border-bottom: 0;
    padding: 15px 0;

    h3 {
      color: $blue4;
    }
  }

  .modalBody {
    height: 100%;
    padding: 15px 0;
  }

  .modalFooter {
    border-top: 0;
  }

  .footerBox {
    background-color: $gray2;
    margin-bottom: 40px;
    padding: 20px 25px 5px;
  }

  .backdrop {
    opacity: .5;
  }

  .closeButton {
    background: none;
    border: 0;
    color: $blue-light;
    font-size: 25px;
    outline: none;
    position: absolute;
    right: 10px;
    top: 10px;
  }
}
