@mixin circle-icons($icon-size, $icon-color) {
  :global(.fa) {
    border: 2px solid $icon-color;
    border-radius: 2000px;
    height: $icon-size;
    padding: 2px;
    width: $icon-size;
  }

  :global(.material-icons) {
    border: 2px solid $icon-color;
    border-radius: 2000px;
    padding: 2px;
  }
}

@mixin right-icon-section($size, $color) {
  float: right;

  @include circle-icons($size, $color);

  button {
    background-color: inherit;
    color: $header-color;
    font-size: 35px;
    margin-left: 15px;
    outline: none;
    padding-left: inherit;
    padding-right: inherit;

    &:focus {
      :global(.material-icons) {
        border-color: $main-text-color;
      }
    }

    &:hover,
    &:focus,
    &:active {
      box-shadow: none;
      outline: none;
    }

    &:disabled {
      color: $gray2;
    }
  }
}
