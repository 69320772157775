@import '../../../theme/variables';

:local {
  // Values for sticky footer
  .wrapper {
    height: 100%;
    margin-top: -$footer-height - $footer-spacing;
    padding-top: $footer-height + $footer-spacing;
  }

  .mainContent {
    margin-bottom: -$footer-height - $footer-spacing; // for footer
    min-height: 100%;
    padding-top: $nav-bar-height + 20px;
    position: relative;
  }

  .toastContainer {
    float: right;
    margin-right: 30px;
    margin-top: 100px;
    width: 300px;
  }

  // spacing for sticky footer
  .pushFooter {
    height: $footer-height + $footer-spacing;
  }
}
