@import '../../../theme/variables';

$nav-bar-shadow-height: 2px;

:local {
  .navbar {
    background: $white;
    box-shadow: 0 $nav-bar-shadow-height 10px 0 $outline-color;
    height: $nav-bar-height - $nav-bar-shadow-height;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;

    a {
      color: $blue-link;
    }
  }

  .blueNavbar
  {
    background-color: $blue5;
    color: $white;
    ul 
    {
      color: $black;
    }
    .logo {
      margin-left: 0;
      margin-top: 16px;
    }
    :global(.biolabicons):hover
    {
      color: $white;
    }
  }

  .apps {
    display: inline-block;
    margin-left: 10px;
    padding-top: 20px;

    :global(.biolabicons) {
      font-size: 30px;
    }
  }

  .mainHeader {
    color: $primary-color;
    font-weight: 600;
  }

  .logo {
    margin-left: -$side-bar-width;
  }

  .navItem {
    cursor: pointer;
    height: 45px;
    margin-top: 15px;

    a {
      font-size: 14px;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 10px;

      > .icon {
        font-size: 25px;
      }

      &:hover,
      &:focus {
        background: transparent;
      }
    }
  }
}
