@import '../../../../theme/variables';

:local {
  .dealer {
    background-color: $white;
    border: 2px solid transparent;
    cursor: pointer;
    margin-bottom: 30px;
    padding: 10px;

    &:hover {
      border: 2px solid $left-nav-offstate-color;
    }

    a:hover {
      text-decoration: none;
    }

    p {
      color: $black;
      text-decoration: none;
    }
  }

  .image {
    border-bottom: 1px solid $gray25;
    margin-bottom: 10px;
    padding-bottom: 10px;
  }

  .name {
    color: $header-color;
  }
}
