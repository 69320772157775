@import '../../../theme/variables';
@import '../../../theme/mixins';

$icon-size: 1.3em; // Used to make round border; ensure same height and width

:local {
  .pageHeader {
    color: $primary-color;
    margin-bottom: 25px;
  }

  .backButton {
    border: 2px solid $header-color;
    border-radius: 2000px;
    color: $header-color;
    cursor: pointer;
    margin-right: 20px;

    &:global(.material-icons) {
      vertical-align: middle;
    }
  }

  .rightSection {
    @include right-icon-section($icon-size, $header-color);
  }
}
