@import '../../../theme/variables';

:local {
  .footer {
    background-color: $gray3;
    height: $footer-height;
    margin-top: $footer-spacing;
    padding-top: 10px;
    position: relative;
    width: 100%;
    z-index: 1000;
  }

  .separator {
    margin: 0 5px;
  }

  .info {
    p {
      color: $white;
    }
  }

  .legalLink {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}
