@import '../theme/variables.scss';

:global {
  body {
    background-color: $body-color;
    color: $main-text-color;
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    height: 100%;
  }

  html {
    height: 100%;
  }

  .btn {
    color: $white;
    font-size: 13px;
    padding-bottom: 5px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 5px;
    position: relative;

    &:focus,
    &:active,
    &:active:focus {
      outline: none;
    }

    &.full-width {
      width: 100%;
    }
  }

  .bold {
    font-weight: bold;
  }

  .semi-bold {
    font-weight: 600;
  }

  button:focus {
    outline: 0;
  }

  .btn-primary {
    background-color: $primary-color;
  }

  .btn-dashed {
    background-color: $white;
    border: 1px dashed $outline-color;
    color: $header-color;

    &:hover {
      background-color: $gray7;
      color: $white;
    }
  }

  .btn-default {
    background-color: $gray7;
  }

  .col-xs-12,
  .col-md-12,
  .col-sm-12,
  .col-lg-12 {
    .btn.pull-left + .btn.pull-left {
      margin-left: 20px;
    }

    .btn.pull-right ~ .btn.pull-right {
      margin-right: 20px;
    }
  }

  .icon-button {
    background: none;
    color: $main-text-color;
    font-size: 24px;
    padding: 0;

    &:active {
      box-shadow: none;

      .round {
        border-color: $main-text-color;
      }
    }

    .biolabicons {
      color: $header-color;

      &:hover {
        color: $main-text-color;
      }
    }

    + .icon-button {
      margin-left: 20px;
    }
  }

  hr {
    margin-bottom: 10px;
    margin-top: 5px;
  }

  label {
    color: $primary-color;
    font-weight: 600;
  }

  small {
    font-size: 85%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  h1 {
    color: $header-color;
    font-size: 29px;
    font-weight: 900;
    padding: .25em 0;
  }

  h2 {
    color: $primary-color;
    font-size: 21px;
    font-weight: 600;
    margin: 20px 0 10px;
    padding: .25em 0;

    sub {
      bottom: 0;
      color: $blue4;
      font-weight: normal;
    }
  }

  h3 {
    font-size: 21px;
    font-weight: 600;
  }

  h4 {
    font-size: 16px;
  }

  h5 {
    font-size: 14px;
    font-weight: bold;
  }

  h6 {
    font-size: 14px;
  }

  td {
    font-size: 13px;
  }

  .input-group-btn .btn {
    height: 35px;
    padding-top: 8px;
  }

  .form-horizontal .form-group {
    margin-bottom: 8px;
  }

  .btn-fade {
    background: none;
    color: $white;
    opacity: .5;

    &.btn:focus {
      outline: none;
    }

    &:hover {
      outline: none;
    }

    &.active,
    &:active {
      background: none;
      box-shadow: none;
      opacity: 1;
      outline: none;
    }
  }

  .table-striped {
    tbody tr {
      &:nth-child(2n) {
        background-color: $body-color;
      }

      &:nth-child(2n-1) {
        background-color: $white;
      }
    }
  }

  .table-hover {
    tbody tr {
      border-bottom: 2px solid transparent;

      &:hover {
        border-bottom: 2px solid $blue-light;
      }
    }
  }

  .heading-seperator {
    border-left: 2px solid $gray5;
    display: inline-block;
    height: 20px;
    margin: 0 18px;
  }

  .b { font-weight: bold; }
  .i { font-style: italic; }
  .tr { text-align: right; }
  .tc { text-align: center; }
  .red { color: $red1; }
  .pointer { cursor: pointer; }
  .inline-block { display: inline-block; }
  .clearfix {
    clear: both;
  }

  .material-icons {
    vertical-align: bottom;

    &.md-18 { font-size: 18px; }
    &.md-24 { font-size: 24px; }
    &.md-36 { font-size: 36px; }
    &.md-48 { font-size: 48px; }
  }
}
