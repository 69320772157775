@font-face {
	font-family: "biolabicons";
	font-display: block;
	src: url("/Content/biolabicons.eot?#iefix") format("embedded-opentype"),
url("/Content/biolabicons.woff") format("woff"),
url("/Content/biolabicons.woff2") format("woff2"),
url("/Content/biolabicons.ttf") format("truetype");
}

.biolabicons {
	line-height: 1;
}

.biolabicons:before {
	font-family: biolabicons !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: top;
}

.icon-add:before {
	content: "\f101";
}
.icon-app:before {
	content: "\f102";
}
.icon-appManagement:before {
	content: "\f103";
}
.icon-backArrow:before {
	content: "\f104";
}
.icon-check:before {
	content: "\f105";
}
.icon-checkbox_checked:before {
	content: "\f106";
}
.icon-checkbox_empty:before {
	content: "\f107";
}
.icon-close:before {
	content: "\f108";
}
.icon-dashboard:before {
	content: "\f109";
}
.icon-dealerManagement:before {
	content: "\f10a";
}
.icon-delete:before {
	content: "\f10b";
}
.icon-downArrow:before {
	content: "\f10c";
}
.icon-downBox:before {
	content: "\f10d";
}
.icon-dragHandle:before {
	content: "\f10e";
}
.icon-edit:before {
	content: "\f10f";
}
.icon-error:before {
	content: "\f110";
}
.icon-info:before {
	content: "\f111";
}
.icon-leftArrow:before {
	content: "\f112";
}
.icon-leftDoubleArrow:before {
	content: "\f113";
}
.icon-lock:before {
	content: "\f114";
}
.icon-profile:before {
	content: "\f115";
}
.icon-rightArrow:before {
	content: "\f116";
}
.icon-rightCaret:before {
	content: "\f117";
}
.icon-rightDoubleArrow:before {
	content: "\f118";
}
.icon-roleManagement:before {
	content: "\f119";
}
.icon-search:before {
	content: "\f11a";
}
.icon-unlock:before {
	content: "\f11b";
}
.icon-upBox:before {
	content: "\f11c";
}
.icon-upload:before {
	content: "\f11d";
}
.icon-user_is_locked:before {
	content: "\f11e";
}
.icon-userManagement:before {
	content: "\f11f";
}
